const theme = {
    colors: {
        primary: '#555555',
        secondary: '#C7C4C0',
    },
    assets: {
        mainLogo: './blue-bike.svg',
        secondaryLogo: './&.svg',
    },
};

export default theme;